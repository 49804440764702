<template>
  <!-- Page Content -->
  <div class="my-2">
    <h2 class="text-4xl font-semibold">Users</h2>
    <div class="pb-2 flex items-center justify-between text-gray-600">
      <div>
        <span>
          <span class="text-primary"> {{ totalUsers }} </span>
          users
        </span>
      </div>
    </div>

    <!-- Search Content -->
    <input
      type="search"
      v-model="query"
      placeholder="Search..."
      class="w-full p-2 mb-2"
    />

    <!-- Users Content -->
    <!-- Users Table Content -->
    <table
      class="min-w-max w-full mt-5 table-auto paginated" style="padding: 10px"
      :sticky-header="true"
      :data="filteredUsers"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :loading="loading"
      :mobile-cards="true"
      :paginated="true"
      :per-page="10"
    >
      <!-- Users Table Content Header -->
      <thead>
      <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
        <th
          @click="sort('created')"
          class="heading-text"
          :class="{ sorting: currentSort === 'created' }"
        >
          Created
          <MenuDown
            v-if="isSorted('created', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('created', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('modified')"
          class="heading-text"
          :class="{ sorting: currentSort === 'modified' }"
        >
          Modified
          <MenuDown
            v-if="isSorted('modified', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('modified', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('role')"
          class="heading-text"
          :class="{ sorting: currentSort === 'role' }"
        >
          Role
          <MenuDown
            v-if="isSorted('role', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('role', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('firstName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'firstName' }"
        >
          First Name
          <MenuDown
            v-if="isSorted('firstName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('firstName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('lastName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'lastName' }"
        >
          Last Name
          <MenuDown
            v-if="isSorted('lastName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('lastName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('email')"
          class="heading-text tableColumnWidth"
          :class="{ sorting: currentSort === 'email' }"
        >
          Email
          <MenuDown
            v-if="isSorted('email', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('email', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('formatNationalContactNumber')"
          class="heading-text"
          :class="{ sorting: currentSort === 'formatNationalContactNumber' }"
        >
          Contact Number
          <MenuDown
            v-if="isSorted('formatNationalContactNumber', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('formatNationalContactNumber', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('answeredDiscountSurvey')"
          class="heading-text"
          :class="{ sorting: currentSort === 'answeredDiscountSurvey' }"
        >
          Answered Profile
          <MenuDown
            v-if="isSorted('answeredDiscountSurvey', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('answeredDiscountSurvey', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('utmTags')"
          class="heading-text"
          :class="{ sorting: currentSort === 'utmTags' }"
        >
          UTM Tags
          <MenuDown
            v-if="isSorted('utmTags', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('utmTags', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('unitShortlist')"
          class="heading-text"
          :class="{ sorting: currentSort === 'unitShortlist' }"
        >
          Shortlist
          <MenuDown
            v-if="isSorted('unitShortlist', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('unitShortlist', 'asc')"
            class="inline-block absolute"
          />
        </th>
      </tr>
      </thead>

      <!-- Users Table Content Body -->
      <tbody class="text-gray-600 text-sm font-light">
      <tr
        v-for="user in sortedUsers"
        :key="user.id"
        class="border-b border-gray-200 hover:bg-gray-100"
      >
        <td
          class="py-3 px-6 text-center"
        >
          {{ user.created.seconds | moment("calendar") }}
        </td>
        <td
          class="py-3 px-6 text-center"
        >
          {{ user.modified.seconds | moment("calendar") }}
        </td>
        <td
          class="py-3 px-1 text-center cursor-pointer"
        >
          <select
            v-model="user.role"
            @change="changeRole(user)"
            v-if="hasAccess('users', 'change-user-role')"
          >
            <option v-for="role in roles" :value="role.value" :key="role.value">
              {{ role.key }}
            </option>
            <option v-if="isSuperAdmin" value="superadmin">Super Admin</option>
          </select>
          <span v-else>{{ getRoleText(user.role) }} </span>
        </td>
        <td
          class="py-3 px-6 text-center"
        >
          {{ user.firstName }}
        </td>
        <td
          class="py-3 px-6 text-center"
        >
          {{ user.lastName }}
        </td>
        <td
          class="py-3 px-6 text-center tableColumnWidth"
        >
          <a :href="`mailto:${user.email}`">
            {{ user.email }}
          </a>
        </td>
        <td
          class="py-3 px-3 text-center"
        >
          <a :href="`tel:${user.e164ContactNumber}`">
            {{user.formatNationalContactNumber }}
          </a>
        </td>
        <td
          class="py-3 px-6 text-center"
        >
          {{ user.answeredDiscountSurvey }}
        </td>
        <td
          class="py-3 px-6 text-center"
        >
          <span v-for="item in user.utmTags" :key="item">
            {{ item }}
          </span>
        </td>
        <td
          class="py-3 px-6 text-center"
        >
          <span v-for="unit in user.unitShortlist" :key="unit.id">
          {{ unit.unitName }},
        </span>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Users Table Content Pagination -->
    <p class="pagination-container">
      <button
        v-if="currentPage != 1"
        @click="prevPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        &lt; Prev
      </button>

      <button
        v-if="(this.currentPage*this.pageSize) < this.filteredUsers.length"
        @click="nextPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        Next &gt;
      </button>
    </p>

  </div>
</template>

<script>
import { profilesCollection } from "@/firebase";
import permissionsMixin from "@/mixins/permissions";
import isNil from "lodash/isNil";
import "@mdi/font/css/materialdesignicons.min.css";
import MenuDown from "vue-material-design-icons/MenuDown";
import MenuUp from "vue-material-design-icons/MenuUp";
import {APP_ADMIN_PAGINATION} from "@/Constants";
import firebase from "firebase/app";

export default {
  name: "AdminUsersList",
  mixins: [permissionsMixin],
  components: {
    MenuDown,
    MenuUp,
  },
  data: () => ({
    currentSort:'created',
    currentSortDir:'desc',
    pageSize: APP_ADMIN_PAGINATION,
    currentPage:1,
    users: [],
    loading: true,
    query: "",
    roles: [
      { value: "user", key: "User" },
      { value: "agent", key: "Agent" },
      { value: "agent-principal", key: "Agent Principal" },
      { value: "admin", key: "Admin" },
      { value: "admin-viewonly", key: "Admin View-only" },
    ],
  }),
  methods: {
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction) {
        return true;
      }
      return false;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='desc'?'asc':'desc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredUsers.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    getRoleText(role) {
      return this.roles.find((item) => item.value === role).key;
    },
    changeRole(user) {
      profilesCollection
        .doc(user.id)
        .update({
          role: user.role,
          modified: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "User Updated",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error updating User",
            text: error.message,
          });
        });
    },
    getUsers() {
      profilesCollection.onSnapshot((snap) => {
        let users = [];
        snap.forEach((doc) => {
          users.push({
            id: doc.id,
            role: doc.data().role,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            e164ContactNumber: doc.data().e164ContactNumber,
            formatNationalContactNumber: doc.data().formatNationalContactNumber,
            utmTags: doc.data().utmTags,
            unitShortlist: doc.data().unitShortlist,
            created: doc.data().created,
            modified: doc.data().modified,
            answeredDiscountSurvey: doc.data().answeredDiscountSurvey,
          });
        });
        users.sort((a, b) => {
          return b.created.seconds - a.created.seconds;
        });
        this.users = users;
        this.loading = false;
      });
    },
  },
  computed: {
    sortedUsers:function() {
      let users = this.filteredUsers
      return users.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    filteredUsers: function () {
      if (this.query !== "") {
        const searchQuery = this.query.toLowerCase();
        return this.users.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().includes(searchQuery)) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(searchQuery)) ||
            (item.email && item.email.toLowerCase().includes(searchQuery)) ||
            (item.contactNumber &&
              item.contactNumber.toLowerCase().includes(searchQuery))
        );
      }
      return this.users;
    },
    totalUsers: function () {
      if (!isNil(this.users)) {
        return this.users.length;
      }

      return 0;
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style>
.o-table__th,
.o-table__td {
  @apply text-xs px-1;
}

.o-table__td {
  @apply align-middle;
}

.o-table__td a {
  @apply text-primary;
}

.o-pag__link {
  @apply p-1 px-3;
}

.o-pag__link.o-pag__link--current {
  @apply bg-primary font-semibold;
}

.o-table__td select {
  height: 40px;
  line-height: 40px;
  width: 90px;
  padding: 5px;
  @apply text-xs;
}

.o-table__th:is(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 50px;
  padding: 0.5rem 0.75rem;
  line-height: 1.5rem;
  font-size: .85rem;
}
.tableColumnWidth {
  width: 20%;
  word-wrap: break-word
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  cursor: pointer;
  text-align: center;
  padding: 0.75rem 1.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #fffff333;
}
</style>
